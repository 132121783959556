import _export from "../internals/export";
import _functionUncurryThis from "../internals/function-uncurry-this";
import _notARegexp from "../internals/not-a-regexp";
import _requireObjectCoercible from "../internals/require-object-coercible";
import _toString from "../internals/to-string";
import _correctIsRegexpLogic from "../internals/correct-is-regexp-logic";
var $ = _export;
var uncurryThis = _functionUncurryThis;
var notARegExp = _notARegexp;
var requireObjectCoercible = _requireObjectCoercible;
var toString = _toString;
var correctIsRegExpLogic = _correctIsRegexpLogic;
var stringIndexOf = uncurryThis("".indexOf);

// `String.prototype.includes` method
// https://tc39.es/ecma262/#sec-string.prototype.includes
$({
  target: "String",
  proto: true,
  forced: !correctIsRegExpLogic("includes")
}, {
  includes: function includes(searchString /* , position = 0 */) {
    return !!~stringIndexOf(toString(requireObjectCoercible(this)), toString(notARegExp(searchString)), arguments.length > 1 ? arguments[1] : undefined);
  }
});
export default {};